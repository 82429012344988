.gradeTriangle {
    width: 0px;
    height:0px;
    border-bottom: 12px solid #837200;
    border-left: 12px solid transparent;
    bottom: 0;
    right: 0;
    position: absolute;
    color: green
  }


  .sender .gradeTriangle, .non-roles-order-box.sender {
    color: #FF1AB8;
    border-bottom: 12px solid #FF1AB8;
  }
  
  .participant-1 .gradeTriangle {
    color: #837200;
    border-bottom: 12px solid #837200;
  }
  
  .participant-2 .gradeTriangle {
    color: #167EFB;
    border-bottom: 12px solid #167EFB;
  }
  
  .participant-3 .gradeTriangle {
    color: #232308;
    border-bottom: 12px solid #232308;
  }
  
  .participant-4 .gradeTriangle {
    color: #FF753E;
    border-bottom: 12px solid #FF753E;
  }
  
  .participant-5 .gradeTriangle {
    color: #8E37F1;
    border-bottom: 12px solid #8E37F1;
  }
  
  .participant-6 .gradeTriangle {
    color: #139CDB;
    border-bottom: 12px solid #139CDB;
  }
  
  .participant-7 .gradeTriangle {
    color: #10C78A;
    border-bottom: 12px solid #10C78A;
  }
  
  .anyone .gradeTriangle {
    color: #A3640D;
    border-bottom: 12px solid #A3640D;
  }
  
  .everyone .gradeTriangle {
    color: #005700;
    border-bottom: 12px solid #005700;
  }