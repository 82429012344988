@import "./variables.scss";

html, body {
  height: 100%;
}

html, html * {
  // font-family: 'AvenirLTPro-Book' !important;
  font-family: 'Source Sans Pro', sans-serif !important;
}

html {
  font-size: 100%; // typical default browser font size is 16, so we want to set to 10px
}

.field-value, field-type-formatter {
  font-size: 0.8rem;
}

.template-preview-full-page {
  img, * {
    max-width: 600px;
  }
}

.inline-block {
  display: inline-block;
}

.title-wrapper {
  display: flex;
  align-items: center;

  .title-icon {
    padding-right: 1rem;
  }
}

.no-margin h2, .no-margin h3 {
  margin: 0 0 !important;
}

.duplication-merger-dialog {

  .email-option-col {
    display: none !important;
  }

  .email-address-list-wrapper th, .email-address-list-wrapper td {
    overflow: visible !important;
  }
}

img.img-icon {
  width: 25px;
}

.dw-mobile-table .field-value, .dw-mobile-table field-type-formatter {
  font-size: 0.8rem;
}

.flex-align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.flex-direction-col {
  flex-direction: column;
}

.loading-block {
  padding: 25px;
}

.hover-row {
  .toggle-hover-content .show-when-unhover {
    display: block;
  }

  .toggle-hover-content .show-when-hover {
    display: none;
  }

  &:hover {
    .toggle-hover-content .show-when-unhover {
      display: none;
    }
  
    .toggle-hover-content .show-when-hover {
      display: block;
    }
  }
}

.spinner-relative-wrapper-0-bottom {
  position: relative;

  .loading-shaded-spinner {
    bottom: 0 !important;
  }
}

.video-viewer-preview-wrapper {
  width: 100%;
  position: relative;

  .loading-shaded-spinner {
    bottom: 0 !important;
  }

  .attachmentVideo {
    max-width: 100%;
    text-align: center;
    border: 1px solid #000;
  }
}


@keyframes expandWidth {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.loading-shaded-spinner.purple-loading-bar {
  height: 85px;
  left: 120px;
  right: 120px;
  flex-direction: column;
  background-color: transparent;

  .loading-label {
    font-weight: bold;
    padding-top: 5px;
    color: #0d47a1;
    text-transform: uppercase;
  }

  .purple-loader {
    height: 25px;
    width: 400px;
    background: #fff;
    border-radius: 25px;
    padding: 2px;
  }
  
  .purple-loader > span {
    display: block;
    height: 100%;
    border-radius: 20px;
    background-color: #0d47a1;
    position: relative;
    overflow: hidden;
    animation-name: expandWidth;
    animation-timing-function: ease-out;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
}

.flex-field-outer-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}


.required-message {
  margin-top: -5px;
  font-size: .85rem;
  padding: 0 0 10px 15px;
  color: red;
  font-weight: bold;
}

.star-ratings .rating {
  display: inline-flex;
}

.flex-btn {
  .mat-button-wrapper {
    display: flex;
    align-items: center;
  }

  .btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
  }
}

.container {
  max-width: 100%;

  width: 100%;
  margin: 0 auto;
}

.make-position-relative {
  position: relative !important;
}

.flex-center {
  display: flex!important;
  justify-content: center;
  align-items: center;
}

.activity-content-preview, .activity-icon .material-icons {
  display: flex;
  min-height: 30px;
  align-items: center;
}

.activity-icon .material-icons {
  font-size: 20px;
}

.sms-activity .activity-icon {
  color: rgb(255, 105, 165);
}

.email-activity .activity-icon {
  color: rgb(44, 153, 255);
}

.note-activity .activity-icon {
  color: rgb(95, 99, 242);
}

.call-activity .activity-icon {
  color: rgb(32, 201, 151);
}

.success {
  color: #28a745;
}

.warning {
  color: #ffc107;
}

.danger {
  color: #dc3545;
}

.flexed-btns {
  margin-top: 20px;
}

.font-15 {
  font-size: 15px;
}

.pad-bottom-10 {
  padding-bottom: 10px;
}

.pad-top-10 { 
  padding-top: 10px;
}

.pad-right-10 {
  padding-right: 10px;
}

.pad-left-10 {
  padding-left: 10px;
}

.margin-5 {
  margin: 5px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.white-bg {
  background: #fff;
}

.fa {
  font: normal normal normal 20px/1 FontAwesome !important;
}

.record-chooser {
  cursor: pointer;
}

.material-icons {
  font-family: 'Material Icons' !important;
}

a.btn-flex, a.btn-flex:hover {
  text-decoration: none;
}

md-icon{
  font-family: 'Material Icons' !important;
}

.file-icon-color {
  color: #7c0aa6;
}

.relative {
  position: relative;
}

.hidden {
  display: none;
}

.bold {
  font-weight: bold;
}

.message-text img {
  max-width: 310px !important;
}

.default-flex {
  display: flex;
}

.default-flex-col {
  display: flex;
  flex-direction: column;
}

.flex-end {
  justify-content: flex-end !important;
}

.flex-start {
  justify-content: flex-start !important;
}

.flex-centered {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.default-flex.align-center {
  align-items: center;
}

.default-flex.space-around {
  justify-content: space-around;
}

.default-flex.space-between {
  justify-content: space-between;
}

.default-flex.justify-end {
  justify-content: flex-end;
}

.default-flex.flex-wrap {
  flex-wrap: wrap;
}

.overflow-hidden {
  overflow: hidden;
}

.btn-flex .mat-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.btn-flex .inner-btn-text {
  align-self: center;
  padding-left: 5px;
}

.btn-flex .inner-btn-text.empty {
  align-self: center;
  padding-left: 0;
  width: 0px;
}

.uppercase {
  text-transform: uppercase;
}

.btn-flex.single .mat-button-wrapper {
  padding-left: 3px;
}

.hidden {display: none !important;}

.flexcontainer {
  display: flex; display: -webkit-flex;
  flex: 1 0 auto;
}

.centered-block {
  width: 100%;
  text-align: center;
}

.loading-shaded-spinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.like-mat-error {
  font-size: 75%;
  margin: .6666666667em 0 0 1em;
  color: #f44336;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.card-heading.flex-heading-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.card-body .vertical-center,
.spaced-form .row .vertical-center {
  padding-top: 15px;
}

.sidebar-container {
/*   -webkit-flex: 1;
  flex: 1; */
  border-right: 1px solid #e6ecf5;
}
.main-container {
  // -webkit-flex: 6;
  // flex: 6;
}

.main-container {
  margin-left: 0;
}

.main-container.pinned-sidebar {
  padding-left: 205px;
  width: calc(100vw - 525px);
}

.main-container.pinned-sidebar .nav.inner-nav {
  margin-left: -200px !important;
}

.full-width {
  width: 100%;
  min-width: 100% !important;
}

.flex-grid .full-width {
  width: 98%;
}

.inline-block {
  display: inline-block;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

#page-wrap-inner {
  padding: 10px;
  background: #eff4f7;
}

#page-wrap-inner.no-padding {
  padding: 0;
}

.card {
  .card-title {
      /* padding: 17px;*/ 
      color: #515365;
      margin-top: 5px;
  }

  .card-heading {
      padding: 15px 20px;
      position: relative;
  } 
}

.card.required {
  border: 1px solid red;
}

.edit-header-row {
  display: flex;
  justify-content: space-between;
}

.bg { 
  /* The image used */
  // background-image: url("/uploads/system_images/silk_bg_2.jpg");
  background-image: url('/uploads/system_images/fishing_white.jpg');
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.portal-container {
  min-height: 99%;
  background: #eef0f8;
}

.vertical-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

/* ----- GRID SYSTEM for forms USING FLEXBOX ----- */
.flex-grid {
  display: flex; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -moz-flex; display: -webkit-flex;    
}

.nowrap  { 
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.wrap    { 
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}  

.mr-5 {
  margin-right: 5px;
}

.flex-wrapper.form-field {
  display: flex;
  align-items: center;
  // border-top: 1px solid #e9ecef;
  position: relative;

  height: 100%;
  min-height: 40px;
}

.flex-wrapper .column {
  padding: .4rem;
  box-sizing: border-box;
}

.form-field .title {
  color: #515365;
  font-weight: 700;
  // font-size: 14px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;

  min-width: 110px;

  &.is-required-field::after {
    content: '*';
    margin-left: 5px;
    color: red;
  }
}

.module-profile-outer-wrapper .flex-wrapper.form-field .title {
  display: block;
}

.form-field .field {
  color: #515365;
  // font-size: 14px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;  
  flex-grow: 2;
  // overflow: hidden;
}

.mobile-hidden {
  display: block;
}

.search-trigger.centered {
  display: flex;
  justify-content: center;
}

.inner-nav .flex-grid {
  display: flex;
}

h2 {
    font-size: 22px;
}

.table-option {
  padding-left: 20px;
  .fa {
      font: 20px/1 FontAwesome;
  }
  button.btn.btn-default {
      padding: 8px 10px;
  }
}


.btn-inverse, .bold-font {
  font-weight: bold;
}

.text-success {
  color: #37c936 !important;
}

.text-dark {
  color: #515365 !important;
}

.text-gray {
  color: #ebeef6 !important;
}

.text-info {
  color: #0f9aee !important;
}

.text-warning {
  color: #ffcc00 !important;
}

.text-primary {
  color: $mainButtonColor !important;
}

.text-danger {
  color: #ff3c7e !important;
}

.all-caps {
  text-transform: uppercase;
}

.left-item {
  width: 49%;
  float: left;
}

.right-item {
  width: 49%;
  float: right;
}

h1, h2, h3, h4 {
  color: #515365;
}

.layout-section.available-fields-list {
  margin: 0 25px;
}

.layout-section.scrollable-with-max-height {
  max-height: 350px !important;
  min-width: 150px;
  border: 0.5px solid !important;
  background: #f2f4f8 !important;
  border-color: #515365 !important;  
  overflow-y: scroll;
}

.relationships-dnd .layout-section.scrollable-with-max-height {
  max-height: unset !important;
}

mat-row.row-record-selected-to-preview {
  border: 0.5px solid !important;
  background: #f2f4f8 !important;
  border-color: #515365 !important;
}

/* Card Rules */
.card.give-card-padding {padding: 15px 20px;}
.card { position: relative; background-color: #ffffff; margin-bottom: 15px; border: 1px solid #e6ecf5; border-radius: 5px; transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; }
.card.bg-primary, .card.bg-success, .card.bg-info, .card.bg-warning, .card.bg-danger { color: #e6e6e6; }
.card.bg-primary h1, .card.bg-primary h2, .card.bg-primary h3, .card.bg-primary h4, .card.bg-primary h5, .card.bg-primary h6, .card.bg-success h1, .card.bg-success h2, .card.bg-success h3, .card.bg-success h4, .card.bg-success h5, .card.bg-success h6, .card.bg-info h1, .card.bg-info h2, .card.bg-info h3, .card.bg-info h4, .card.bg-info h5, .card.bg-info h6, .card.bg-warning h1, .card.bg-warning h2, .card.bg-warning h3, .card.bg-warning h4, .card.bg-warning h5, .card.bg-warning h6, .card.bg-danger h1, .card.bg-danger h2, .card.bg-danger h3, .card.bg-danger h4, .card.bg-danger h5, .card.bg-danger h6 { color: #ffffff; }
.card.bg-primary p, .card.bg-success p, .card.bg-info p, .card.bg-warning p, .card.bg-danger p { color: #e6e6e6; }
.card .card-heading { padding: 15px 20px; position: relative; background: #fff; }
.card .card-heading .card-title { margin-bottom: 0px; }
.card .card-body { padding: 15px 20px; box-sizing: border-box; }
.card .card-footer { position: relative; padding: 10px 15px 10px 15px; min-height: 55px; background-color: transparent; }
.card .card-footer .btn { margin: 0px; }
.card .card-footer .btn-flat { font-size: 13px; text-transform: uppercase; }
.card .card-horizon { padding: 0px; position: relative; }
.card .card-horizon .image-container { overflow: hidden; position: absolute; height: 100%; padding: 0px; top: 0px; }
.card.no-bottom-margin { margin-bottom: 0; }

@media only screen and (max-width: 767px) { .card .card-horizon .image-container { position: static; } }

.card .card-horizon .card-body { position: relative; }
.card .card-horizon .background-holder { position: relative; top: 0px; left: 0px; width: 100%; height: 100%; background-size: cover; background-position: center center; background-repeat: no-repeat; z-index: 0; }

@media only screen and (max-width: 767px) { .card .card-horizon .background-holder { min-height: 350px; } }

.card .card-horizon .background-holder.has-content { display: table; }
.card .card-horizon .background-holder.has-content .content { display: table-cell; vertical-align: middle; padding: 0px 15px; }
.card:before { content: ""; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; z-index: 2; visibility: hidden; opacity: 0; background: #ffffff; /* The Fallback */ background: rgba(255, 255, 255, 0.85); transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; }
.card-block { padding: 30px; }

.portlet { position: absolute; top: 0px; right: 0px; z-index: 1; }
.portlet .portlet-item { list-style: none; padding: 18px 20px; }
.portlet .portlet-item > li { display: inline-block; margin-right: 3px; }
.portlet .portlet-item > li > a { margin-bottom: 0px; margin-right: 0px; padding: 5px 6px; font-size: 14px; }
.portlet .dropdown-menu { -webkit-transform-origin: top right; -moz-transform-origin: top right; -ms-transform-origin: top right; transform-origin: top right; transform: scale(0, 0); -webkit-transform: scale(0, 0); -moz-transform: scale(0, 0); -o-transform: scale(0, 0); -ms-transform: scale(0, 0); transition: transform 150ms ease-out; -webkit-transition: transform 150ms ease-out; -moz-transition: transform 150ms ease-out; -o-transition: transform 150ms ease-out; -ms-transition: transform 150ms ease-out; display: block; margin: 0px; border-radius: 0px; left: auto; right: -10px; }
.portlet .show .dropdown-menu { transform: scale(1, 1); -webkit-transform: scale(1, 1); -moz-transform: scale(1, 1); -o-transform: scale(1, 1); -ms-transform: scale(1, 1); }

.card-title { font-family: 'Source Sans Pro', sans-serif !important; color: #515365; margin-top: 5px; margin-bottom: 15px; }
.card-media { position: relative; }
.card-media img { width: 100%; }
.card-refresh:before { visibility: visible; opacity: 1; }
.card-refresh:after { content: ""; position: absolute; top: calc(50% - 20px); left: calc(50% - 20px); border: 3px solid #e6ecf5; /* Light grey */ border-top: 3px solid #7774e7; /* Blue */ border-radius: 50%; width: 40px; height: 40px; z-index: 3; -webkit-animation: spin 1.2s linear infinite; -moz-animation: spin 1.2s linear infinite; -ms-animation: spin 1.2s linear infinite; -o-animation: spin 1.2s linear infinite; animation: spin 1.2s linear infinite; transform: translateX(-50%) translateY(-50%); -webkit-transform: translateX(-50%) translateY(-50%); -moz-transform: translateX(-50%) translateY(-50%); -o-transform: translateX(-50%) translateY(-50%); -ms-transform: translateX(-50%) translateY(-50%); }
a.card { display: block; }
a.card:hover, a.card:focus { -webkit-box-shadow: 0 7px 15px rgba(0, 0, 0, 0.075) ; -moz-box-shadow: 0 7px 15px rgba(0, 0, 0, 0.075) ; box-shadow: 0 7px 15px rgba(0, 0, 0, 0.075) ; }

.screen-height {
  min-height: 100%;
}

.card-tabs {
  position: relative;
}

.card-tabs .nav.nav-tabs {
  height: 69px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, .16);
  border: none;
  margin-bottom: 25px;
  transition: all .2s ease-in-out;
}

.card-tabs .nav-item {
  flex: 1;
  text-align: center;
  position: relative;
}

.card-tabs .nav-item a {
  padding: 20px 0 !important;
  color: #37474f;
}

.card-tabs .nav-link:hover {
  border-bottom: 4px solid #ddd;
}

.card-tabs .nav-link.active {
  border-bottom: 4px solid $mainButtonColor !important;
  color: #0f9aee !important;
}

.card-tabs .nav-item a {
  padding: 20px 0 !important;
  color: #37474f;
}


.mat-header-cell .filter-status-text-new {
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: red;
  font-size: 14px;
  display: none;
}

.mat-header-cell .filter-status-text-new.show {
  display: block;
}

.site-main .mat-header-cell .btn.btn-default.clear-bg-btn-new {
  width: 100%;
  border: 0;
  padding: 0;
  padding-top: 2px;
}

.red-icon-new {
  color: #dc3545;
}

.site-main .btn.highlighted {
  color: #dc3545;
}

.flex-field {
  flex: 100%;
  max-width: 100%;
}

.pull-right .btn {
  margin-right: 10px;
}

.cover-profile .btn-primary {
  padding: 10px 20px;
}

.flex-wrapper .column {
  flex: 100%;
}

.flex-field.flex-force-full {
  flex: 100%;
}

.site-main {
  color: #888da8;
  height: 100%;
  // min-height: calc(100vh - 200px);
  overflow: auto;
}

.cke_notifications_area {
  display: none;
}



.ngx-material-timepicker {
  padding-top: 50px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.ngx-material-timepicker-heading__title {
  font-weight: 400;
  font-size: 25px;
  margin: 0;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.ngx-material-timepicker-examples__title {
  font-weight: 400;
  font-size: 22px;
}
.ngx-material-timepicker-examples__container {
  margin-bottom: 30px;
}
.ngx-material-timepicker-example {
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  overflow: hidden;
}
.ngx-material-timepicker-example__body {
  padding: 15px;
}
.ngx-material-timepicker-example__description {
  margin: 0 0 15px;
}
.ngx-material-timepicker-example__form-group {
  position: relative;
  max-width: 300px;
  width: 100%;
  height: 36px;
  border: 1px solid rgba(0, 0, 0, 0.09);
}
.ngx-material-timepicker-example__form-group:not(:last-child) {
  margin-bottom: 20px;
}
.ngx-material-timepicker-example__form-group--toggled {
  display: flex; align-items: center;

  height: 48px;
  border-radius: 5px;
  padding: 10px;
  margin: .25em 0;
}
.ngx-material-timepicker-example__form-group--disabled {
  border-bottom-style: dashed;
}
.ngx-material-timepicker-example__form-group
  input {
  width: 100%;
  height: 100%;
  border: 0;
  font-size: 0.85rem;
}
.ngx-material-timepicker-example__form-group
  input:disabled {
  background-color: transparent;
  cursor: not-allowed;
}
.ngx-material-timepicker-example__form-group
  input:focus {
  outline: 0;
}
.ngx-material-timepicker-example__form-group
  input::-webkit-input-placeholder {
  font-size: 16px;
  color: #ccc;
}
.ngx-material-timepicker-example__form-group
  input::-moz-placeholder {
  font-size: 16px;
  color: #ccc;
}
.ngx-material-timepicker-example__form-group
  input::-ms-input-placeholder {
  font-size: 16px;
  color: #ccc;
}
.ngx-material-timepicker-example__form-group
  input::placeholder {
  font-size: 16px;
  color: #ccc;
}


/* ---- Detail Pages ---- */
.profile-bg-img {
  min-height: 219px; 
}

.cover-btn {
  bottom: 0;
  right: 36px;
  position: absolute;
}

.user-info .media-body {
  padding: 20px;
}

.user-info .media-body, .user-info .media-left {
  vertical-align: middle;
}

.card-block.user-info {
  position: absolute;
  width: 100%;
  bottom: 10px;
  padding: 1.25rem;
  color: #fff;
}

.card-block.user-info .col-md-12 {
  padding-bottom: 0px;
}  

.media-left {
  .profile-image {
    display: none;
  }
}

.user-info .media-body {
  background: $mainButtonColor;
  border: 2px solid #fff;
  border-radius: 10px;
  box-shadow: 0 3px 9px rgba(0,0,0,.5);
}

.user-info .media-body, .user-info .media-left {
  display: table-cell;
  width: auto;
}

/* ---- END Detail Pages ---- */

.mock-link {
  display: inline-block;
  cursor: pointer;
  color: #4243f1;

  &:hover {
    text-decoration: underline;
  }
}

.no-underlined a.link-name {
  text-decoration: none;
}

.no-underlined a.link-name:hover {
  text-decoration: underline;
}


header.dash-main-header {
  padding: 20px 0;
  background: $primaryThemeColor;

  h3.dash-title {
      font-size: 17px;
      font-weight: 500;
      color: #fff;
      padding: 0 1rem 0 0;
      margin: 0;
      text-align: center;
  }
}


.advanced-pie-legend .legend-items-container .legend-items .legend-item .item-value {
  margin-top: 0 !important;
}


.account-calendar-main-wrapper header.custom-calendar-header-wrapper .left-col {
  justify-content: flex-start !important;
}

.account-calendar-main-wrapper header.custom-calendar-header-wrapper .right-col {
  justify-content: flex-end !important;
}


.yeehro-block-icon {
  position: relative;

  .text {
    position: relative;
    color: #fff;
    padding-left: 30px;
    font-size: 20px;
    line-height: 30px;
    color: #7C0AA6;
    font-weight: bold;

    &:after,
    &:before {
        width: 15px;
        height: 30px;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
    }

    &:before {
        animation: rotate 2s linear normal;
        background: $blueColor;
        transform: rotate(45deg);
        z-index: 900;
    }

    &:after {
        background: $redColor;
        animation: rotate 1.5s linear normal;
        transform: rotate(-45deg);
        z-index: 1000;
    }
  }
}

.inner-html-with-giphys .img-wrapper {
  width: auto;
  max-width: 100%;
  height: auto;

  img {
      width: auto;
      max-width: 100%;
      height: auto;
      object-fit: contain;
  }
}


[class^=uil-]:before, [class*=" uil-"]:before {
  font-size: 24px;
  margin-left: 0;
  margin-right: 0;
}

.chartModifyMenuWrapper+* .cdk-overlay-pane {
  left: 0 !important;
  width: max-content !important;
  max-width: 100% !important;
  height: 100% !important;
  top: 0px !important;
}

@media (max-width: 768px) {
  .chartModifyMenuWrapper+* .cdk-overlay-pane {
    min-width: unset !important;
  }
}


.cal-month-view .cal-day-cell > .date-selected {
  border: 1px dotted #444cb9 !important;
  height: 100%;
}


.flexcontainer.portal-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;

  .main-container {
    flex: 1;
  }
  
  .bumpout-sideBar-wrapper, aside{
    /* 12em is the width of the sidebars */
    flex: 0 0 300px;
    transition: margin 0.3s ease;
  }
}


@media (max-width: 680px) {
  .container {
    max-width: 540px;
  }

  .flex-wrapper.form-field {
    display: flex;
    flex-wrap: wrap;
  }

  .profile-accordions-wrapper {
    .flex-wrapper.form-field {
      flex-wrap: unset;
    }
  }

  .template-preview img {
    max-width: 100% !important;
  }

  .mobile-hidden {
    display: none;
  }
  .flex-grid {
      // display: block;
  } 
  .inner-nav .flex-grid {
    display: flex;
  }  

  .site-wrapper .site-main {
    margin-top: 0;
  }    

  .nav.inner-nav {
    margin-top: 0;
  }  

  .main-container.pinned-sidebar, .main-container.pinned-sidebar .nav.inner-nav {
    margin-left: 0 !important;
  }

  .pad-for-mobile {
    margin-top: 30px !important;
  }
}


@media (min-width: 680px) {
  .field-type a {
    display: inline-block;
    max-width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;
  }
}

.allow-link-wrap .field-type a {
  white-space: unset;
}


@media (min-width: 681px) {
  .chat-bar-wrapper.sidebar-is-pinned .chat-message-bar-list-wrapper {
    padding: 0 70px 0 200px;
  }
}


@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}


@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}


@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1300px) {
  .field-type a {
    max-width: 180px;
  }
}


@media (min-width: 1400px) {
  .container {
    max-width: 1340px;
  }

  .field-type a {
    max-width: 250px;
  }
}