h3 {
  margin: 0 0 10px;
}

pre {
  background-color: #f5f5f5;
  padding: 15px;
}

.tooltip-shadow {
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
}

.day-info-tooltip, .add-event-tooltip {
  position: absolute;
  border: 1px solid #ddd;
  width: 450px;
  min-height: 300px;
  z-index: 500;
  background: #fff;
  border-radius: 5px;
  left: 30%;
  top: 16%;
}

.event-list-item {
  color: #fff;
  padding: 2px;
  font-size: 14px;
  cursor: pointer;
}

.tooltip-contents {
  position: relative;
}

.tooltip-header {
  height: 125px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.tooltip-action-bar-wrapper {
  width: 100%
}

.tooltip-action-bar {
  color: #fff;
  padding-right: 20px;
  padding-top: 20px;
  width: 100%;
  text-align: right;
}

.add-event-tooltip .tooltip-action-bar {
  padding-right: 5px;
  padding-top: 5px;
}

.icon-bar {
  position: absolute;
  right: 10px;
  top: 10px;
}

.tooltip-action-bar .icon-bar-item {
  font-size: 20px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  float: left;
  text-align: center;
  border-radius: 50%;
  border: 1px solid transparent;
}

.tooltip-action-bar .icon-bar-item:hover {
  border: 1px solid #fff;
}

.icon-bar-item.white-bar {
  color: #626060;
}

.tooltip-action-bar .icon-bar-item.white-bar:hover {
  background: #f5f5f5;
}

.title-wrapper, .calendar-group-wrapper {
  overflow: hidden;
  width: 85%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.calendar-group-wrapper {
  padding-bottom: 10px;
}

.event-title {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  line-height: 26px;
  padding-top: 30px;
}

.add-event-tooltip .event-title {
  color: #00000061;
  padding-top: 0;
}

.edit-icon {
  background: yellow;
  border-radius: 50%;
  margin-top: 50px;
  color: #fff;
}

.edit-icon-abs {
  position: absolute;
  top: 105px;
  left: 6px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2);
  cursor: pointer;    
}

.edit-icon-abs:hover {
  border-color: 1px solid black;
}

.tooltip-body {
  color: #626060 !important;
}

.item-row {
  padding-top: 10px;
}

.date-label-column {
  display: table;
}

.date-label-cell {
  display: table-cell;
  vertical-align: middle;
  padding-top: 5px;
}

.add-event-tooltip .tooltip-body {
  padding-top: 0;
}

.flex-wrapper {
  display: flex;
  justify-content: space-between;
}


.color-box {
  height: 20px;
  width: 20px;
  border: 1px solid #626060 ;
  border-radius: 4px;
  align-self: center;
}


.full-page-calendar .cal-month-view .cal-day-cell {
  height: calc(100vh / 6.15);
  min-height: 100px !important;
  overflow: hidden;
}

.cal-month-view .cal-header {
  text-align: center;
  font-weight: bolder; }

.cal-month-view .cal-cell-row:hover {
  background-color: #fafafa; }

.cal-month-view .cal-header .cal-cell {
  padding: 5px 0;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  display: block;
  white-space: nowrap; }

.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: #ededed; }

.cal-month-view .cal-days {
  border: 1px solid #e1e1e1;
  border-bottom: 0; }

.cal-month-view .cal-cell-top {
  min-height: 65px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }

.cal-month-view .events-list {
  margin-top: -30px;
  overflow: hidden;
  overflow-y: auto;
}          

.cal-month-view .cal-cell-row {
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex; }

.cal-month-view .cal-cell {
  float: left;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; }

.cal-month-view .cal-day-cell {
  min-height: 100px; }

.cal-month-view .cal-day-cell:not(:last-child) {
  border-right: 1px solid #e1e1e1; }

.cal-month-view .cal-days .cal-cell-row {
  border-bottom: 1px solid #e1e1e1; }

.cal-month-view .cal-day-badge {
  margin-top: 18px;
  margin-left: 10px;
  background-color: #b94a48;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: white;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 10px; }

.cal-month-view .cal-day-number {
  font-size: 1.2em;
  font-weight: 400;
  opacity: 0.5;
  margin-top: 15px;
  margin-right: 15px;
  float: right;
  margin-bottom: 10px; }

.cal-month-view .cal-events {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin: 3px;
  line-height: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.cal-month-view .cal-event {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: 2px; }

.cal-month-view .cal-day-cell.cal-in-month.cal-has-events {
  cursor: pointer; }

.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
  opacity: 0.1;
  cursor: default; }

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: darkred; }

.cal-month-view .cal-day-cell.cal-today {
  background-color: #f5f5f5;
}

.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  font-size: 18px; 
  background-color: #007bff;
  color: #fff;
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: 50%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cal-month-view .cal-day-cell.cal-drag-over {
  background-color: #e0e0e0 !important; }

.cal-month-view .cal-open-day-events {
  padding: 15px;
  color: white;
  background-color: #555;
  -webkit-box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5); }

.cal-month-view .cal-open-day-events .cal-event {
  position: relative;
  top: 2px; }

.cal-month-view .cal-event-title {
  color: white; }

.cal-month-view .cal-out-month .cal-day-badge,
.cal-month-view .cal-out-month .cal-event {
  opacity: 0.3; }

.cal-week-view .cal-day-headers {
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  margin-bottom: 3px;
  border: 1px solid #e1e1e1;
  margin-left: 2px;
  margin-right: 2px; }

.cal-week-view .cal-day-headers .cal-header {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  padding: 5px; }

.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right: 1px solid #e1e1e1; }

.cal-week-view .cal-day-headers .cal-header:hover,
.cal-week-view .cal-day-headers .cal-drag-over {
  background-color: #ededed; }

.cal-week-view .cal-day-headers span {
  font-weight: 400;
  opacity: 0.5; }

.cal-week-view .cal-events-row {
  position: relative;
  height: 33px; }

.cal-week-view .cal-event-container {
  display: inline-block;
  position: absolute; }

.cal-week-view .cal-event {
  padding: 0 10px;
  font-size: 14px;
  margin-left: 2px;
  margin-right: 2px;
  height: 30px;
  line-height: 30px; }

.cal-week-view .cal-draggable {
  cursor: move; }

.cal-week-view .cal-starts-within-week .cal-event {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

.cal-week-view .cal-ends-within-week .cal-event {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.cal-week-view .cal-header.cal-today {
  background-color: #e8fde7; }

.cal-week-view .cal-header.cal-weekend span {
  color: #8b0000; }

.cal-week-view .cal-event,
.cal-week-view .cal-header {
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap; }

.cal-day-view {
  /* stylelint-disable-next-line selector-type-no-unknown */ }
  .cal-day-view .cal-hour-rows {
    width: 100%;
    border: solid 1px #e1e1e1;
    overflow-x: scroll;
    position: relative; }
  .cal-day-view .cal-hour:nth-child(odd) {
    background-color: #fafafa; }
  .cal-day-view mwl-calendar-day-view-hour-segment,
  .cal-day-view .cal-hour-segment {
    display: block; }
  .cal-day-view .cal-hour-segment::after {
    content: '\00a0'; }
  .cal-day-view .cal-hour:not(:last-child) .cal-hour-segment,
  .cal-day-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-bottom: thin dashed #e1e1e1; }
  .cal-day-view .cal-time {
    font-weight: bold;
    padding-top: 5px;
    width: 70px;
    text-align: center; }
  .cal-day-view .cal-hour-segment.cal-after-hour-start .cal-time {
    display: none; }
  .cal-day-view .cal-hour-segment:hover,
  .cal-day-view .cal-drag-over .cal-hour-segment {
    background-color: #ededed; }
  .cal-day-view .cal-event-container {
    position: absolute; }
  .cal-day-view .cal-event {
    border: solid 1px;
    padding: 5px;
    font-size: 14px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
  .cal-day-view .cal-draggable {
    cursor: move; }
  .cal-day-view .cal-starts-within-day .cal-event {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
  .cal-day-view .cal-ends-within-day .cal-event {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }
  .cal-day-view .cal-all-day-event {
    padding: 8px;
    border: solid 1px; }

.cal-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  font-size: 11px;
  word-wrap: break-word;
  opacity: 0.9; }

.cal-tooltip.cal-tooltip-top {
  padding: 5px 0;
  margin-top: -3px; }

.cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000; }

.cal-tooltip.cal-tooltip-right {
  padding: 0 5px;
  margin-left: 3px; }

.cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000; }

.cal-tooltip.cal-tooltip-bottom {
  padding: 5px 0;
  margin-top: 3px; }

.cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000; }

.cal-tooltip.cal-tooltip-left {
  padding: 0 5px;
  margin-left: -3px; }

.cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000; }

.cal-tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.cal-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }


.cal-week-view, .cal-day-view {
  .cal-hour-segment .cal-time {
    display: block;
  }

  .cal-day-columns .cal-day-column .cal-hour-segment {
    cursor: pointer;
  }

  .cal-starts-within-day .cal-event, .cal-current-time-marker {
    min-height: 25px;
  }
}